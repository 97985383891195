import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_place_item = _resolveComponent("place-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        title: $setup.t('UserProfilePage.feedback'),
        parentPath: "/profile"
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.place)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, { color: "primary" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('place')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_place_item, { place: $setup.place }, null, 8, ["place"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, { color: "primary" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('FeedbackPage.contactInfo')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: $setup.person,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('FeedbackPage.nickname')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        placeholder: $setup.t('FeedbackPage.enterNickname'),
                        modelValue: $setup.feedback.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.feedback.name) = $event))
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: $setup.call,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('UserProfilePage.phone')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        placeholder: $setup.t('FeedbackPage.enterPhone'),
                        inputmode: "numeric",
                        modelValue: $setup.feedback.phone,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.feedback.phone) = $event))
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: $setup.mail,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('FeedbackPage.email')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        placeholder: $setup.t('FeedbackPage.enterEmail'),
                        type: "email",
                        modelValue: $setup.feedback.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.feedback.email) = $event))
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, { color: "primary" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('FeedbackPage.feedback')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        class: "icon-lg",
                        icon: $setup.chatbubbleEllipsesOutline,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('FeedbackPage.feedback')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_textarea, {
                        placeholder: $setup.t('FeedbackPage.enterFeedback'),
                        modelValue: $setup.feedback.message,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.feedback.message) = $event))
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, { color: "primary" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('photos')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.photos, (photo) => {
                        return (_openBlock(), _createBlock(_component_ion_col, {
                          size: "4",
                          key: photo
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: photo.base64Data,
                              onClick: ($event: any) => ($setup.showActionSheet(photo))
                            }, null, 8, _hoisted_1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _createVNode(_component_ion_col, { size: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card, { class: "photo-card ion-align-items-center ion-justify-content-center ion-text-center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_fab_button, {
                                class: "centered-fab-btn",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.takePhoto()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.camera }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                class: "submit-btn",
                expand: "block",
                onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.submitFeedback()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('submit')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}