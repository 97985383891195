
// Vue
import { computed, reactive, ref } from 'vue';

// icons
import { close, mail, person, call, chatbubbleEllipsesOutline, camera, trash, } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonList, IonListHeader,
        IonInput, IonTextarea, IonFabButton, IonCard,
        loadingController, toastController, } from '@ionic/vue';
import PlaceItem from '@/components/PlaceItem.vue';

// services
import CommonService from '@/services/CommonService';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { usePhotoGallery, Photo } from '@/composables/usePhotoGallery';

export default {
  name: 'FeedbackPage',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonList, IonListHeader, IonInput, IonTextarea, IonFabButton, IonCard,
                PlaceItem, },
  setup() {
    const { photos, takePhoto, showActionSheet } = usePhotoGallery();
    const store = useStore();
    const user = computed(() => store.state.user);
    const feedback = reactive({
      name: user.value.firstName || "",
      phone: user.value.phone || "",
      email: user.value.email || "",
      floor: "",
      unit: "",
      message: "",
    })
    const place = ref<any>(null);
    const { passedPlace } = history.state; // route paramters (mainly ID)
    if (passedPlace) { // from project detail page (enquiry)
      place.value = JSON.parse(passedPlace);
    }
    // methods
    const { t } = useI18n();
    const submitFeedback = async () => {
      const loading = await loadingController.create({});
      await loading.present();
      CommonService.createNewFeedback(feedback, photos.value, place.value);
      feedback.message = "";
      const toast = await toastController.create({
        message: t('FeedbackPage.feedbackSubmitted'),
        duration: 5000,
        position: 'top',
      });
      toast.present();
      loading.dismiss();
    }
    
    return {
      // icons
      close, mail, person, call, chatbubbleEllipsesOutline, camera,

      // variables
      feedback, place, photos,

      // methods
      t, submitFeedback,
      
      takePhoto, showActionSheet,
    }
  },
}
